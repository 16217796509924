<!-- 新的视频查看评论组件 -->
<template>
    <div>
        <el-dialog title="查看评论" :visible.sync="show" width="70%" @close="exit()">
            <el-table :data="commentlist" border style="width: 100%">
                <el-table-column prop="id" v-if="false"></el-table-column>
                <el-table-column prop="author_name" label="姓名" width="120">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.author_name == ''||scope.row.author_name == null">匿名用户{{scope.row.id}}</span>
                        <span v-else>{{scope.row.author_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tel" label="电话" width="110"></el-table-column>
                <el-table-column prop="comment" label="内容"></el-table-column>
                <el-table-column width="200">
                    <template slot-scope="scope">
                        <div class="opt">
                            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delcomment(scope.row,scope.$index)">删除 </el-button>
                            <!-- <el-button size="mini" type="success" icon="el-icon-chat-dot-square" @click="replay(scope.row)">回复 </el-button> -->
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <p class="paging">
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                    :current-page="current1" :page-sizes="pageSizes1" :page-size="PageSize1"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalCount1" :hide-on-single-page="value1">
                </el-pagination>
            </p>
        </el-dialog>
        <el-dialog title="客服回复" :visible.sync="dialogVisible" width="30%">
            <el-form label-width="80px">
                <el-form-item label="姓名">
                    <el-input type="text" v-model="commentreplyname"></el-input>
                </el-form-item>
                <el-form-item label="回复内容">
                    <el-input type="textarea" v-model="commentreply"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitkefutalk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        props: ['showpl', 'plid', 'from'],
        inject:['reload'],
        data() {
            return {
                show: false,
                id: '',
                commentlist: [],
                current1: 1,//默认显示第几页
                totalCount1: 0,// 总条数，根据接口获取数据长度
                pageSizes1: [20],// 个数选择器（可修改）
                PageSize1: 20,// 默认每页显示的条数（可修改）
                value1: false,
                dialogVisible: false,
                commentreplyname: '',
                commentreply: '',
                replyrow: '',
            }
        },
        watch: {
            showpl(o, n) {
                this.show = o;
            },
            plid(o, n) {
                this.id = o;
                this.getdetail(1);
            }
        },
        mounted() {

        },
        methods: {
            exit() {
                this.show = false;
                this.$emit('update:showpl', false);
            },
            handleSizeChange1() { },
            handleCurrentChange1(currentPage) {
                this.getdetail(currentPage);
            },
            getdetail(page) {
                var url;
                if (this.from == 'xy') {
                    url = '/xyvideo/get_gu_xy_video_comment?xy_video_id=' + this.id + "&page=" + page + "&size=" + this.PageSize1
                } else {
                    url = "/item/get_gu_item_comment?item_id=" + this.id + "&page=" + page + "&size=" + this.PageSize1
                }
                this.axios.get(url)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.commentlist = res.data.result;
                            this.totalCount1 = res.data.count;
                        }else{
                            this.commentlist = [];
                            this.totalCount1 = 0;
                        }
                    })
            },
            delcomment(row, index) {
                var url,zd;
                let form = {};
                form.id = row.id;
                if (this.from == 'xy') {
                    url = "/xyvideo/delete_gu_xy_video_comment"//
                    form.xy_video_id = this.rowid
                } else {
                    url = '/item/delete_gu_item_comment'
                    form.item_id = this.rowid;
                }
                this.$confirm('此操作将永久删除该评论, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post(url, this.qs.stringify(form))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.commentlist.splice(index, 1);
                                this.totalCount1--;
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            replay(row) {
                this.dialogVisible = true;
                this.replyrow = row;
                this.commentreply = '';
                this.commentreplyname = '';
            },
            submitkefutalk() {
                let form, url;
                if (this.from == 'xy') {
                    form = {
                        comment: this.commentreply,
                        author_id: sessionStorage.getItem('cust_id'),
                        author_name: this.commentreplyname,
                        headimg: sessionStorage.getItem('headimg'),
                        reply_id: this.replyrow.id,
                        xy_video_id: this.replyrow.xy_video_id,
                    }
                    url = "/xyvideo/insert_gu_xy_video_comment"
                } else {
                    form = {
                        comment: this.commentreply,
                        author_id: sessionStorage.getItem('cust_id'),
                        author_name: this.commentreplyname,
                        headimg: sessionStorage.getItem('headimg'),
                        reply_id: this.replyrow.id,
                        showcust_id: this.replyrow.showcust_id,
                    }
                    url = "/showcust/insert_gu_showcust_comment"
                }
                // console.log(form);
                this.axios.post(url, this.qs.stringify(form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.$message({
                                message: '回复成功',
                                type: 'success'
                            });
                            this.dialogVisible = false;
                            // this.reload();
                            this.getdetail(1);
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>